import axios from "@/config/axios";

export const postAPICall = async (method, endPoint, params = {}) => {
    try {
        let options = {
            method: method,
            url: endPoint
        };

        if (method === 'GET') {
            options.params = params;
        } else {
            options.data = params;
        }

        const response = await axios(options);
        return response.data;
    } catch (error) {
        console.error("Error in API call:", error);
        throw error;
    }
};